import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import { FormattedMessage } from "gatsby-plugin-intl";
import ContactForm from "../components/contactform";
import Layout from "../components/layout";
import SEO from "../components/Seo";

const ContactPage = ({ data }) => {
  const site = data.site.siteMetadata;
  return (
    <Layout title={site.siteTitle}>
      <SEO title='Contact' keywords={[`iproperty`, `contact`]} />

      <div id='contact-page' className='container transition-fade'>
        <div id='contact'>
          <div className='container'>
            <div className='contact-content'>
              <h1 className='contact-content-title'>
                <FormattedMessage id='contact_title' />
              </h1>
              <p>
                <FormattedMessage id='contact_i' />
              </p>
              <span className='block'>
                <a className='phone-link' href={`tel:${site.callable}`}>
                  {site.phone}
                </a>
              </span>
              <span className='block'>
                <a className='phone-link' href={`mailto:${site.mail}`}>
                  {site.mail}
                </a>
              </span>
            </div>
            <hr />
            <div className='contact-form'>
              <ContactForm />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

const contactPageQuery = graphql`
  query {
    site: site {
      siteMetadata {
        title
        keywords
        slogen
        social {
          twitter
        }
        phone
        phone_name
        callable
        mail
      }
    }
  }
`;


const ContactPageContainer = (props) => {
  const data = useStaticQuery(contactPageQuery);
  return <ContactPage {...props} data={data} />;
};

export default ContactPageContainer;
